import { ChangeDetectorRef, EventEmitter, Component, OnDestroy, OnInit, Output, effect, inject, input, signal } from "@angular/core";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { groupBy, includes } from "lodash";

import { FileSelectEvent } from "primeng/fileupload";

import { currentActiveId } from "../../../state/Sidebar/sidebar.selector";
import { isMobileViewSelector } from "../../../state/Assets/assets.selector";

import { SplitScreenService } from "../../../services/split-screen.service";
import { ImageUploadService } from "../../../services/image.upload.service";
import { EventManagementService } from "../../../services/eventManagement.service";


import { CONSTANT } from "../../../config/constant";


const SIDEBAR_ID = CONSTANT.SIDEBAR_IDS.MULTIPLE_ASSETS_CREATION_SIDEBAR;
@Component({
  selector: "app-multiple-assets-creation",
  templateUrl: "./multiple-assets-creation.component.html",
  styleUrl: "./multiple-assets-creation.component.scss",
})
export class MultipleAssetsCreationComponent implements OnInit, OnDestroy {
  store = inject(Store);
  translate = inject(TranslateService);
  cd = inject(ChangeDetectorRef);
  splitScreenService = inject(SplitScreenService);
  eventManagementService = inject(EventManagementService);
  imageUploadService = inject(ImageUploadService);

  formConfiguration = input<any>(null);
  resetForm = input<number>(0);
  rowInputFieldsCount = input<number>(0);
  multipleAssetsCount = input<number>(0);
  showForm = input<boolean>(false);

  @Output() createModalClosedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() emmitAssetsData: EventEmitter<any> = new EventEmitter();

  showSidebarForm = signal<boolean>(false);
  showLoader = signal<boolean>(false);
  uploadImageDialog = signal<boolean>(false);
  isMobileView = signal<boolean>(false);
  isFormLabelsTranslated = signal<boolean>(false);
  isSidebarClosed = signal<boolean>(true);
  formLabel = signal<string>("");
  formDataForRendering = signal<any[]>([]);
  multipleAssets = signal<IAsset[]>([]);
  uploadItemList = signal<any[]>([])
  subscriptionsList = signal<Subscription[]>([]);
  sidebarId = signal<string>(SIDEBAR_ID);
  selectedUploadItem = signal<any>(null);
  separatorExp = signal<RegExp>(/,| /);
  currentAssetIdx = signal<number>(0)
  currentImageSelections = signal<Object>({})

  ngOnInit(): void {
    this.collectSubscriptions(
      this.store.select(currentActiveId).subscribe((res) => {
        if (res !== this.sidebarId()) {
          this.isFormLabelsTranslated.set(false);
        }
      })
    );
    this.collectSubscriptions(
      this.store.select(isMobileViewSelector).subscribe((isMobileView: boolean) => {
        if (isMobileView !== null && isMobileView !== undefined) {
          this.isMobileView.set(isMobileView)
        }
      })
    )
  }

  collectSubscriptions(sub: Subscription) {
    const subscriptionsList = this.subscriptionsList();
    subscriptionsList.push(sub);
    this.subscriptionsList.set(subscriptionsList);
  }


  constructor() {
    effect(
      () => {
        if (this.resetForm()) {
          this.renderForm();
        }
        if (typeof this.showForm() === 'boolean') {
          this.showForm() && this.splitScreenService.addOrRemoveSplitScreen('addSplitScreen', '#' + SIDEBAR_ID);
          !this.showForm() && this.splitScreenService.addOrRemoveSplitScreen("removeSplitScreen", "#" + SIDEBAR_ID);
          this.showSidebarForm.set(this.showForm())
        }
        this.formLabel.set(this.formLabel());
        this.formDataForRendering.set(this.formDataForRendering());
      },
      { allowSignalWrites: true }
    );
  }

  ngOnDestroy(): void {
    this.subscriptionsList().forEach((sub) => sub.unsubscribe());
  }

  renderForm() {
    if (this.formConfiguration()?.["label"]) {
      this.formLabel.set(this.formConfiguration()["label"]);
      this.setformData();
    }
  }

  getLangLabel(labelCode: string) {
    const translate = new TranslatePipe(this.translate, this.cd);
    if (labelCode) {
      return translate.transform(labelCode);
    }
  }
  setformData() {
    if (!this.isFormLabelsTranslated()) {
      this.showLoader.set(true);
      this.isFormLabelsTranslated.set(true);
      const { configuration, assetLinkConfig } = this.extractLinkableAssetsData();
      let formDataForRendering = this.translateLabelsForForm(this.formatAssetConfig(configuration, assetLinkConfig));
      this.getAllDynamicData(formDataForRendering.filter(({ type }) => type !== 'hidden')).then((fdata) => {
        formDataForRendering = this.setNumberOfRows(fdata);
        this.formDataForRendering.set(formDataForRendering)
        this.createNewAssetModel(false, this.multipleAssetsCount());
      });

    }
  }



  extractLinkableAssetsData() {
    const formConfiguration = { ...this.formConfiguration(), assetLinkConfig: {} };
    const uploadItemList = []
    for (const imgType in formConfiguration?.config?.fileUpload) {
      uploadItemList.push(formConfiguration?.config?.fileUpload[imgType])
      break
    }
    if (uploadItemList.length) {
      formConfiguration.assetLinkConfig['image'] = {
        config: {
          type: 'image',
          label: 'Upload Image',
          description: 'Upload Image'
        }
      }
    }
    this.uploadItemList.set(uploadItemList)


    formConfiguration?.config?.assetLinkConfig?.assetTypes?.forEach((asset: string) => {
      formConfiguration.assetLinkConfig[asset] = {
        config: formConfiguration?.config?.assetLinkConfig?.[asset] || {}
      }
    })
    return formConfiguration

  }

  getImageTypeLabel(imgType: string) {
    return this.uploadItemList().find(({ type }) => type === imgType)?.name || 'Image'
  }

  setNumberOfRows(formData) {
    const N = this.provideNumberOfRows(formData.length);
    const groupedData = [];
    formData
      .filter(({ type }) => type && !this.isTypeToHide(type))
      .forEach((fData, idx) => {
        if (groupedData?.[groupedData?.length - 1]?.length === N || !idx) {
          groupedData.push([fData]);
        } else {
          groupedData?.[groupedData?.length - 1]?.push(fData);
        }
      });
    return groupedData;
  }

  isTypeToHide(type: string): boolean {
    return ["hidden"].includes(type);
  }

  translateLabelsForForm(formData) {
    return formData.map((fieldData) => {
      if (fieldData.type === "dropDown") {
        return {
          ...fieldData,
          dropDownValues: fieldData.dropDownValues.map((dValue) => {
            return { ...dValue, label: this.getLangLabel(dValue.label) };
          }),
        };
      }
      return fieldData;
    });
  }

  closePopup() {
    if (!this.isSidebarClosed()) {
      this.createModalClosedEvent.emit(true);
    }
    this.isSidebarClosed.set(true);
  }

  submitAssetsCreation() {
    const linkableAssets: string[] = this.formConfiguration()?.config?.assetLinkConfig?.assetTypes || [];
    const fieldsToRemove = ['isValid', 'assetIdx', 'imageCount'];
    const multipleAssets = this.multipleAssets()
      .filter(({ isValid }) => !!isValid)
      .map(assetData => {
        linkableAssets?.forEach((linkableAsset: string) => {
          const data = Array.isArray(assetData[linkableAsset]) ? assetData[linkableAsset] : assetData[linkableAsset] ? [assetData[linkableAsset]] : null;
          if (data) {
            assetData.assetsToLink[linkableAsset] = data
          }
          delete assetData[linkableAsset];
          fieldsToRemove.forEach(fr => {
            assetData[fr]
          })
        })
        return assetData
      });
    this.emmitAssetsData.emit(multipleAssets)
  }

  createNewAssetModel(addNew: boolean, numberOfAssets: number) {
    const assetFields = {};
    this.showLoader.set(true);
    this.formDataForRendering().forEach((f) => {
      f?.forEach(({ type, field, accordianData }) => {
        if (type === 'accordian') {
          assetFields[field] = {};
          accordianData.forEach(data => {
            assetFields[field][data.field] = data.type === 'boolean' ? false : null
          })
        }
        else if (!this.isTypeToHide(type)) {
          assetFields[field] = null;
        }
      });
    });
    const multipleAssets = addNew ? this.multipleAssets() : [];
    numberOfAssets = Math.max(1, numberOfAssets || 0);
    for (let i = 0; i < numberOfAssets; i++) {
      multipleAssets.push({
        assetIdx: multipleAssets.length,
        ...assetFields,
        assetsToLink: {},
        isValid: false
      });
    }
    this.showLoader.set(false);
    this.multipleAssets.set(multipleAssets);
  }

  removeAsset(assetIdxToRemove: number) {
    this.multipleAssets.set(
      this.multipleAssets()
        .filter((asset) => asset.assetIdx !== assetIdxToRemove)
        .map((asset, assetIdx) => {
          asset.assetIdx = assetIdx;
          return asset;
        })
    );
  }

  isValidSingleAsset(asset: IAsset) {
    const stringOrNumberBindingTypes = [
      "text",
      "email",
      "number",
      "telephone",
      "textarea",
      "dropDown",
      "colorPicker",
      "date"
    ];
    const arrayBindingTypes = ["multiSelect", "multiText"];

    let validity = true;
    for (const groupedFields of this.formDataForRendering()) {
      for (const indField of groupedFields) {
        if (indField.required) {
          if (stringOrNumberBindingTypes.includes(indField.type)) {
            if (
              !asset[indField.field] &&
              asset[indField.field] !== false) //avoiding boolean false value
            {
              validity = false;
            }
          } else if (arrayBindingTypes.includes(indField.type)) {
            if (!asset[indField.field]?.length) {
              validity = false;
            }
          }
        }
        if (!validity) break;
      }
      if (!validity) break;
    }
    const multipleAssets = this.multipleAssets();
    multipleAssets[asset.assetIdx].isValid = validity;
  }

  countValidAssetsData() {
    return this.multipleAssets().filter(({ isValid }) => !!isValid)?.length;
  }

  convertToQueryString(params, type) {
    let queryString = "";
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        if (params[key] == "accountId" && type == "query") {
          // queryString += `&${key}=${this.selectedAccountId}&`;
        } else {
          queryString +=
            type == "path"
              ? `${key}/${params[key]}/`
              : `&${key}=${params[key]}&`;
        }
      }
    }
    queryString = queryString.slice(0, -1);
    return queryString;
  }

  getDynamicDataByApi(url: string) {
    return new Promise((resolve) => {
      this.eventManagementService
        .genericGetApiCall(url)
        .subscribe((_response: any) => {
          resolve(_response);
        });
    });
  }

  getDataByPath(o: any, s: any) {
    s = s.replace(/\[(\w+)\]/g, ".$1");
    s = s.replace(/^\./, "");
    var a = s.split(".");
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }

  resolvepathNavigation(path: string, obj: any) {
    return path?.split(".")?.reduce(function (prev, curr) {
      return prev ? prev[curr] : null;
    }, obj || self);
  }

  getAllDynamicData(formConfigdata) {
    return new Promise((resolve) => {
      const allPromises = [];
      const groupedByFieldname = groupBy(formConfigdata, "field");
      const allFieldNames = Object.keys(groupedByFieldname);
      allFieldNames?.forEach((fieldName, index) => {
        let fieldObj = groupedByFieldname[fieldName][0];
        if (
          fieldObj["isDynamic"] === true &&
          ((
            fieldObj["type"] === "preFill" &&
            !fieldObj["fieldValue"]) ||
            (fieldObj["type"] !== "preFill"))
        ) {
          let url = "";
          if (fieldObj["apiDetails"] && fieldObj["apiDetails"]["url"]) {
            url = fieldObj["apiDetails"]["url"];
          }
          if (fieldObj["apiDetails"]["pathParams"]) {
            let params = fieldObj["apiDetails"]["pathParams"];
            url += this.convertToQueryString(params, "path");
          }
          if (fieldObj["apiDetails"]["queryParams"]) {
            let params = fieldObj["apiDetails"]["queryParams"];
            url += "?" + this.convertToQueryString(params, "query");
          }
          if (url && url.length > 0) {
            const promise = this.getDynamicDataByApi(url).then((_response) => {
              fieldObj = { ...fieldObj, response: {} };
              fieldObj = { ...fieldObj, response: _response };
              return fieldObj;
            });
            allPromises.push(promise);
          }
        }
        if (allFieldNames.length - 1 === index) {
          if (allPromises.length > 0) {
            Promise.all(allPromises).then((data) => {
              const grpRespByFields = groupBy(data, "field");
              const resFields = Object.keys(grpRespByFields);
              // eslint-disable-next-line prefer-const
              let dropDownResponseConfigured = {};
              let fillValue = {};
              resFields?.forEach((resField) => {
                const resFieldObj = grpRespByFields[resField][0];
                const dropDownDetails = resFieldObj["dropDownDataDetails"];
                const dataPath = resFieldObj["dataPath"];
                if (dropDownDetails) {
                  const dynamicResponses = resFieldObj["response"];
                  const labelPointer =
                    dropDownDetails["label"]; /* .split('.') */
                  const valuePointer =
                    dropDownDetails["value"]; /* .split('.') */
                  const queryPointer =
                    dropDownDetails["query"];
                  const dropDownValue = dynamicResponses.map(
                    (dynamicResponse) => {
                      const label = this.resolvepathNavigation(
                        labelPointer,
                        dynamicResponse
                      );
                      const value = this.resolvepathNavigation(
                        valuePointer,
                        dynamicResponse
                      );
                      const query = this.resolvepathNavigation(
                        queryPointer,
                        dynamicResponse
                      );
                      return { label, value, query };
                    }
                  );
                  dropDownResponseConfigured[resField] = [];
                  dropDownResponseConfigured[resField] = dropDownValue;
                }
                if (dataPath) {
                  const dynamicResponses = resFieldObj["response"];
                  const fillValueData = { data: dynamicResponses };
                  const preFillData = this.getDataByPath(
                    fillValueData,
                    dataPath
                  );
                  fillValue[resField] = preFillData;
                }
              });


              const tempData = formConfigdata.map
                (
                  (formObject) => {
                    const _fieldName = formObject["field"];
                    if (includes(resFields, _fieldName)) {
                      if (formObject["dropDownDataDetails"]) {
                        formObject = {
                          ...formObject,
                          dropDownValues: dropDownResponseConfigured[_fieldName],
                        };
                      }
                      if (formObject["type"] === "preFill") {
                        formObject = {
                          ...formObject,
                          fieldValue: fillValue[_fieldName],
                        };
                        formObject = {
                          ...formObject,
                          value: fillValue[_fieldName],
                        };
                      }
                    }
                    return formObject;
                  }
                )

              resolve(tempData);
            });
          } else {
            resolve(formConfigdata);
          }
        }
      });
    });
  }


  uploadIconClicked(assetIdx: number) {
    this.uploadImageDialog.set(true);
    this.selectedUploadItem.set(this.uploadItemList()?.[0])
    this.currentAssetIdx.set(assetIdx)
  }


  transformFileName(fileName: string) {
    const fN: string = fileName + '';
    const result = fN.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }


  storeImagesForUpload(evt: FileSelectEvent) {
    const currentImageSelections = this.currentImageSelections();

    (evt?.currentFiles || []).forEach((fileDetails) => {
      this.imageUploadService.getImageAsBase64(fileDetails?.['objectURL']?.changingThisBreaksApplicationSecurity).then(res => {
        currentImageSelections[this.selectedUploadItem().type] = (!this.selectedUploadItem()?.multiple || currentImageSelections[this.selectedUploadItem().type]) ? {} : currentImageSelections[this.selectedUploadItem().type]
        currentImageSelections[this.selectedUploadItem().type][fileDetails.name + fileDetails.size] = res
      })
    })
    this.currentImageSelections.set(currentImageSelections)
  }

  clearSelectedImages() { this.uploadImageDialog.set(false); this.currentImageSelections.set({}) }

  removeImage({ file }) {
    const currentImageSelections = this.currentImageSelections();
    delete currentImageSelections[this.selectedUploadItem().type][file.name + file.size];
    this.currentImageSelections.set(currentImageSelections)

  }
  confirmImagesForUpload(assetIdx: number) {
    const multipleAssets = this.multipleAssets();
    multipleAssets[assetIdx].image = { ...(multipleAssets[assetIdx].image || {}) }

    if (!this.selectedUploadItem()?.multiple) {
      multipleAssets[assetIdx]['image'][this.selectedUploadItem().type] = {}
    }
    multipleAssets[assetIdx].image = { ...this.currentImageSelections() }
    this.uploadImageDialog.set(false);
    let imageCount = 0;
    for (const imgType in multipleAssets[assetIdx].image) {
      for (const label in multipleAssets[assetIdx].image[imgType]) {
        if (label) {
          imageCount += 1
        }
      }
    }
    multipleAssets[assetIdx].imageCount = imageCount
    this.currentImageSelections.set({})
    this.multipleAssets.set(multipleAssets)
  }

  provideNumberOfRows(C: number) {
    if (this.isMobileView()) {
      return C
    } if (this.rowInputFieldsCount()) {
      return this.rowInputFieldsCount()
    } if (window.innerWidth < 920) {
      // 3 input columns for tablet screen
      return Math.max(2, Math.ceil(C / 3))
    }
    // 6 input columns for laptop screen

    return Math.max(2, Math.ceil(C / 6))

  }

  removeSelectedImage(imgType: string, imgLabel: string, assetIdx: number) {
    const multipleAssets = this.multipleAssets();
    delete multipleAssets[assetIdx]['image'][imgType][imgLabel]
    multipleAssets[assetIdx]['imageCount']--;
    this.multipleAssets.set(multipleAssets)
  }


  formatAssetConfig(assetConfig, assetLinkConfig) {
    const arr = []
    for (const key in assetConfig) {
      key && assetConfig.hasOwnProperty(key) && arr.push(assetConfig[key])
    }

    for (const key in assetLinkConfig) {
      assetLinkConfig?.[key]?.['config'] && arr.push(assetLinkConfig[key]['config'])
    }
    return arr;
  }


  handleTriggerDropdownChange(fieldProps: IFieldProperties, assetData: IAsset) {
    const config = this.formConfiguration()?.configuration[fieldProps?.apiDetails?.triggerFields?.[0]]
    if (!config?.apiDetails?.attachToUrl) {
      fieldProps?.apiDetails?.url && this.handleDropdownChange(this.multipleAssets()[assetData.assetIdx], fieldProps);
      return;
    }

    let createdURL = config?.apiDetails?.url;
    (config?.apiDetails?.fieldsToBeAttachedToUrl || []).forEach(({ key }) => {
      createdURL = createdURL.split(key).join(
        // assetData[fieldProps.field]
        this.getQueryValue(assetData[fieldProps.field], fieldProps['dropDownDataDetails'].query, fieldProps['dropDownValues'])
      );

    });


      this.eventManagementService.searchValueInAssetConifg(createdURL).subscribe((el: any[]) => {
        const dropDownValues: { [key: string]: string }[] = [];
        const { label: labelKey, value: valueKey } = config['dropDownDataDetails'] || {}
        el?.forEach((ele) => {
          dropDownValues.push({
            label: ele?.[labelKey],
            value: ele?.[valueKey]
          });
        });
        const multipleAssets = this.multipleAssets();
        multipleAssets[assetData.assetIdx][config.field + '_dropDownValues'] = dropDownValues
        multipleAssets[assetData.assetIdx][config.field] = dropDownValues[0]?.['value']
        this.isValidSingleAsset(assetData);
        this.multipleAssets.set(multipleAssets);
        this.handleDropdownChange(multipleAssets[assetData.assetIdx], config)
      });


  }


  handleDropdownChange(assetData: IAsset, fieldProps: IFieldProperties) {
    if (!fieldProps?.apiDetails?.triggerFields?.length && !fieldProps?.apiDetails?.attachToUrl) {

      const apiDetails = fieldProps.apiDetails;
      const finalURL = apiDetails?.url + '&' + apiDetails?.serachQuery?.field + "=" + assetData[fieldProps.field];
      this.callingGenericAPIWithSettingResults(finalURL, fieldProps, assetData)

    } else if (fieldProps?.apiDetails?.attachToUrl) {

      let createdURL = fieldProps?.apiDetails?.url
      let fieldsToAttach = fieldProps?.apiDetails?.fieldsToBeAttachedToUrl;

      fieldsToAttach.forEach((replacement) => {
        const { key, value } = replacement;
        const values = value.split(".");

        const qryConfig = this.getFieldProperties(values[0]);
        const isQuery = qryConfig?.dropDownDataDetails?.query;
        const dropDownValues = qryConfig.dropDownValues;

        createdURL = createdURL.split(key).join(
          this.getQueryValue(
            assetData[values[0]],
            isQuery,
            dropDownValues
          )
        );
      });

      createdURL += assetData[fieldProps.field]
      this.callingGenericAPIWithSettingResults(createdURL, fieldProps, assetData)
    }

  }



  callingGenericAPIWithSettingResults(url: string, fieldProps: IFieldProperties, assetData: IAsset) {
    this.eventManagementService.searchValueInAssetConifg(url).subscribe((res: any[]) => {

      const matchedItem = res.find(item => item.name === assetData[fieldProps.field]);
      const fieldData = this.formConfiguration().configuration;
      const multipleAssets = this.multipleAssets();

      for (const fKey in fieldData) {
        if (fieldData[fKey]['prefillFrom'] === fieldProps.field) {
          multipleAssets[assetData.assetIdx][fKey] = this.getNestedValue({ [fieldProps.field]: matchedItem }, fieldData[fKey]['prefillValuePath'])
        }
      }
      this.isValidSingleAsset(assetData);
      this.multipleAssets.set(multipleAssets);
    });
  }

  getNestedValue(object: any, path: string): string {
    return path?.split('.').reduce((acc, key) => (acc && acc[key] !== undefined) ? acc[key] : null, object);
  }
  getQueryValue(id, isQuery, dropDownValues) {
    if (isQuery) {
      return dropDownValues.find(({ value }) => id === value)?.query || id
    }
    return id
  }

  getFieldProperties(fieldKey: string) {
    for (const fGroup of this.formDataForRendering()) {
      for (const field of fGroup) {
        if (field.field === fieldKey) {
          return field
        }
      }
    }
  }
}

interface IAsset {
  isValid: boolean
  assetIdx: number
  assetsToLink: any
  image?: any
  imageCount?: number
}

interface IFieldProperties {
  field: string;
  apiDetails: {
    attachToUrl: boolean;
    url: string;
    triggerFields: string[];
    fieldsToBeAttachedToUrl: ({ key: string, value: string })[];
    serachQuery: {
      field: string
    }
  };
}