/**
 * Created by chandru on 21/3/19.
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ConfigService} from './config.service';
import { forkJoin, map, of } from 'rxjs';

@Injectable()
export class TrackingService {
  constructor(private http: HttpClient, public configService: ConfigService) {
  }



    getCarAnalyticssHistoricDataById(startDate, endDate, assetId) {
        let url = this.configService.appConfig.appBaseUrl + 'gpsData/historicalV2/startDate/' + startDate + '/endDate/' + endDate + '/assetId/' + assetId
        // if(series){
        //     url = url + '&series='+series
        // }
      return this.http.get(url);
    }

  getCarSensorData(startDate, endDate, assetId, series?,interval?) {
    let url = this.configService.appConfig.appBaseUrl + 'gpsData/historicalSensorData/startDate/' + startDate + '/endDate/' + endDate + '/assetId/' + assetId;
    if(series && interval){
      url = url + '?series=' + series + "&interval=" + interval;
    }
    else if (series) {
      url = url + '?series=' + series;
    }
    else if(interval){
      url = url + '?interval=' + interval;
    }

    return this.http.get(url);
  }

  getAllCarsSensorData(startDate, endDate, carsSelected, series?,interval?) {
    const distanceAllObservable = carsSelected?.map((carSelected) => {
      let url = this.configService.appConfig.appBaseUrl + 'gpsData/historicalSensorData/startDate/' + startDate + '/endDate/' + endDate + '/assetId/' + carSelected?._id;
    if(series && interval){
      url = url + '?series=' + series + "&interval=" + interval;
    }
    else if (series) {
      url = url + '?series=' + series;
    }
    else if(interval){
      url = url + '?interval=' + interval;
    }

    return this.http.get(url).pipe(map(res => ({ carSelected, res })));
    });
    
    return forkJoin(distanceAllObservable);
  }

  getMultipleSensorData(startDate, endDate, assetId, series?) {
    let url = this.configService.appConfig.appBaseUrl + 'gpsData/historicalSensorData/startDate/' + startDate + '/endDate/' + endDate + '/multiple/assetId/' + assetId;
    if (series) {
      url = url + '?series=' + series;
    }
    return this.http.get(url);
  }

  getCarGpsHistoricalData(startDate, endDate, assetId, series?) {

    if (!assetId) {
      return of([{res: 'noAssetId'}]);
    }

    if (series) {
      let url = this.configService.appConfig.appBaseUrl + 'gpsData/historicalGpsV2/startDate/' + startDate + '/endDate/' + endDate + '/assetId/' + assetId + '?series=' + series;
      return this.http.get(url);
    } else {
      let url = this.configService.appConfig.appBaseUrl + 'gpsData/historicalGpsV2/startDate/' + startDate + '/endDate/' + endDate + '/assetId/' + assetId;
      console.log(url);
      return this.http.get(url);

    }
  }

  getCarGpsHistoricalDataById(startDate, endDate, id, fInclude, series?) {

    if (!id) {
      return of([]);
    }

    let url = this.configService.appConfig.appBaseUrl + 'gpsData/historicalGpsV2/startDate/'+startDate+'/endDate/'+endDate+'/assetId/'+id+'?fI=' + (fInclude ? fInclude : '');
        if (series) {
          url = url + '&series=' + series;
        }
    return this.http.get(url)
  }

  getCarTotalDistanceTravelled(startDate, endDate, assetId) {
     return this.http.get(this.configService.appConfig.appBaseUrl + 'gpsData/distanceMovedV2/'+ startDate + '/' + endDate + '/' + assetId)
    // return this.http.get(this.configService.appConfig.appBaseUrl + 'gpsData/totalDistance/' );
  }

  getCarTotalDistanceTravelledById(startDate, endDate, assetId){
    let url = this.configService.appConfig.appBaseUrl + "gpsData/distanceMovedV2ByAssetId/"+startDate+'/'+endDate+'/'+assetId
    return this.http.get(url)
  }

  getAllCarsTotalDistanceTravelledById(startDate, endDate, carsSelected){
    const distanceAllObservable = carsSelected?.map((carSelected) => {
      let url = this.configService.appConfig.appBaseUrl + "gpsData/distanceMovedV2ByAssetId/" + startDate + '/' + endDate + '/' + carSelected._id;
      return this.http.get(url).pipe(map(res => ({ carSelected, res })));
    });
    
    return forkJoin(distanceAllObservable);
  }

  // getCarTrackerEventData(startDate, endDate, assetId, series?) {
  //   let url = this.configService.appConfig.appBaseUrl + 'trackerEvent/all-v2/historical/'+startDate+'/'+endDate+'/'+assetId
  //   //   return this.http.get(this.configService.appConfig.appBaseUrl + 'trackerEvent/historical/' + startDate + '/' + endDate + '/' + vinNumber);
  // }

  getAggregatedCarTrackerEventData(startDate, endDate, vinNumber, series? , timeZone?, type?, status?) {

    let url = this.configService.appConfig.appBaseUrl + 'trackerEvent/aggregatedByEventType/' + startDate + '/' + endDate + '/' + vinNumber;
    if (!series) {
       series = 'hour';
    }
    if (!timeZone) {
      timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    url = url + '?series=' + series + '&timezone=' + timeZone;
    if (type) {
       url = url + '&type=' + type;
    }
    if (status) {
      url = url + '&status=' + status;
    }
    return this.http.get(url );
  }

  getAggregatedCarIdlingEventData(startDate, endDate, vinNumber, series? , timeZone? ) {
    if (!series) {
      series = 'hour';
    }
    if (!timeZone) {
      timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return this.http.get(this.configService.appConfig.appBaseUrl + 'trackerEvent/aggregatedIdlingDuration/' + startDate + '/'
      + endDate + '/' + vinNumber + '?series=' + series + '&timezone=' + timeZone );
  }

  getAllLastLocationOfAssets() {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'gpsData/all/lastKnownLocation/');
  }

  getCarDistanceMovedData(startDate, endDate, assetId, series?) {
    let url = this.configService.appConfig.appBaseUrl+ "gpsData/distanceMovedV2/startDate/"+startDate+"/endDate/"+endDate+"/assetId/"+assetId

    if (!series) {
      url = url + '?series=hour';
    }
    

    return this.http.get(url)

    // if (!timeZone) {
    //   timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // }

    // return this.http.get(this.configService.appConfig.appBaseUrl + 'gpsData/distanceMoved/' + startDate + '/'
    //   + endDate + '/' + vinNumber + '?series=' + series + '&timezone=' + timeZone );
  }

  getAllCarDistanceMovedData(startDate, endDate, carsSelected,series='hour') {
  
    const distanceAllObservable = carsSelected?.map((carSelected) => {
      let url = this.configService.appConfig.appBaseUrl+ "gpsData/distanceMovedV2/startDate/"+startDate+"/endDate/"+endDate+"/assetId/"+carSelected?.['_id']
      
        url += '?series=' + series;
      
      return this.http.get(url).pipe(map(res => ({ carSelected, res })));
    });
    
    return forkJoin(distanceAllObservable);
  }

  getTotalDistanceMovedByAllCar(startDate, endDate, timeZone?) {
    if (!timeZone) {
      timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return this.http.get(this.configService.appConfig.appBaseUrl +
      'gpsData/totalDistanceMovedByAllCar/' + startDate + '/' + endDate + '/' + '?timezone=' + timeZone);

  }

  getOdoMeterByRange(range?: string) {
    let url = this.configService.appConfig.appBaseUrl + 'asset/carConnected/odoMeterByRange';
    if (range) {
      url = url + '?range=' + range;
    }
      return this.http.get(url);
  }

  getTotalDistanceMovedByAllCarByDay(startDate, endDate, timeZone?) {
    if (!timeZone) {
      timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return this.http.get(this.configService.appConfig.appBaseUrl +
      'gpsData/totalDistanceMovedByAllCarByDay/' + startDate + '/' + endDate + '/' + '?timezone=' + timeZone);
  }

  getDriversLinked() {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'asset/obdDevice/driversLinked');
  }

  getAllCarEventsAndStatus(type?: string) {
    let url = this.configService.appConfig.appBaseUrl + 'trackerEvent/types';
    if (type) {
      url = url + '?type=' + type;
    }
    return this.http.get(url);
  }

  getAllEventCounts (assetId, type, status, startDate, endDate, moduleType?) {

   /*  let url = this.configService.appConfig.appBaseUrl + 'trackerEvent/all-v2/eventCount/'+assetId+'?startDate='+startDate+"&endDate="+endDate;

    return this.http.get(url) */
     return this.http.get(this.configService.appConfig.appBaseUrl + 'trackerEvent/all-v2/eventCount/' + assetId + '?type=' +
       type + '&status=' + status + '&startDate=' + startDate + '&endDate=' + endDate + '&eventType=' + moduleType);
  }

  getCarEventAndStatusCount(car, type, status, startDate, endDate) {
    let url = this.configService.appConfig.appBaseUrl + 'trackerEvent/all-v2/count/assetId/' + car + '?startDate=' + startDate + '&endDate=' + endDate;
    if(type){
        url = url + '&type='+type
    }
    if(status){
        url = url + '&status='+status
    }
    return this.http.get(url);
  }

  getCarEventAndStatusData(car, type, status, startDate, endDate, skip, limit) {
    let url = this.configService.appConfig.appBaseUrl + 'trackerEvent/all-v2/assetId/' + car + '?startDate=' + startDate + '&endDate=' + endDate + '&skip=' + skip + '&limit=' + limit

    if(type){
        url = url + '&type='+type
    }

    if(status){
        url = url + '&status='+status
    }

    return this.http.get(url);
  }

  getDataLocationPlotForEvents(startDate, endDate, car, type, status) {
    /* let url = this.configService.appConfig.appBaseUrl + 'trackerEvent/all-v2/historical/'+startDate+'/'+endDate+'/'+car
    if(type){
        url = url + '?type=' + type
    }
    if(status){
        url = url  + '&status=' + status
    }

    return this.http.get(url); */

     return this.http.get(this.configService.appConfig.appBaseUrl + 'trackerEvent/all-v2/historical/' + startDate + '/' + endDate + '/' + car + '?type=' +
       type + '&status=' + status);
  }

  getAllCarsInGeoFenceForSpecifiedTimeFrame(geoFenceData) {
    return this.http.post(this.configService.appConfig.appBaseUrl + 'gpsData/areaAnalytics', geoFenceData );
  }
}
